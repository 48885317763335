<template>
  <div>
    <section
      v-for="(section, idx) in sections"
      :key="idx"
    >
      <SectionTitle :title="section.title" />
      <component
        :is="section.component"
        :client="client"
        :lineOfBusiness="lineOfBusiness"
        :moods="moods"
        :sellers="sellers"
        :agencies="agencies"
      />
    </section>
  </div>
</template>

<script>
export default {
  components: {
    SectionTitle: () => import('@/components/SectionTitle'),
    FormCard: () => import('@/components/FormCard'),
    BasicData: () => import('./BasicData'),
    Contacts: () => import('./Contacts'),
    Locations: () => import('./Locations'),
    LoginData: () => import('./LoginData'),
  },
  props: {
    client: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    sections: [
      { title: 'Dados Básicos', component: 'basic-data' },
      { title: 'Contatos', component: 'contacts' },
      { title: 'Endereços', component: 'locations' },
      { title: 'Dados de login', component: 'login-data' },
    ],
    lineOfBusiness: [],
    moods: [],
    sellers: [],
    agencies: [],
    is_ready: 0,
  }),
  methods: {
    getAll() {
      this.$api
        .get('people/get_form/client')
        .then((res) => {
          this.moods = res.data.moods
          this.sellers = res.data.sellers
          this.agencies = res.data.agencies
          this.lineOfBusiness = res.data.lines

          this.sellers.map((seller) => {
            seller.title = seller.name + ' - ' + seller.email
          })
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.is_ready = 1
        })
    },
  },
  created() {
    this.getAll()
  },
}
</script>
